import { gql, ApolloClient } from '@apollo/client';
import axios from 'axios';
import { Config } from '@/aggregator/config/env';
import toast from 'react-hot-toast';
import * as types from './types';

export const FRAGMENT_CARRIER = gql`
  fragment FragmentCarrier on ExportCarrier {
    uuid
    externalId
    publicId
    dotNumber
    status
    primaryContactName
    name
    phone
    disabledMerchants
    disabledLocations
    isDtnaCarrier
    showDiscountFlag
    address {
      street1: street
      city
      state
      zipCode
    }
    showTimeOnReceipts
    showRetailOnEmailedReceipts
    fraudPreventionEnabled
    noCreditCheck
    creditRefresh
    samsaraEnabled
    motiveEnabled
    carrierAlertEmails
    programAlertEmails
    carrierCreditAlertAmount
  }
`;

export const QUERY_GET_CARRIERS = gql`
  query GetCarriers($programUuid: String!) {
    getCarriers(programUuid: $programUuid) {
      ...FragmentCarrier
    }
  }
  ${FRAGMENT_CARRIER}
`;

export const GET_FEES = gql`
  query GetFees($carrierUuid: String, $programUuid: String) {
    getFees(carrierUuid: $carrierUuid, programUuid: $programUuid) {
      carrierUuid
      programUuid
      asOfDate
      flatAmount
      percentAmount
      type
      productCategory
      feeCategory
      merchantUuid
      perGallonAmount
    }
  }
`;

function getCarrierCacheId(carrierUuid: string) {
  return `ExportCarrier:{"uuid":"${carrierUuid}"}`;
}

export function getCarrierFromFragment(
  client: ApolloClient<object>,
  carrierId: string,
) {
  const carrier = client.readFragment<types.CarrierType>({
    id: getCarrierCacheId(carrierId),
    fragment: FRAGMENT_CARRIER,
  });

  return carrier;
}

export async function setCarrierCreditLineService(
  values: any,
  carrierUuid: string | undefined,
) {
  return axios
    .post(
      `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/credit-lines`,
      values,
    )
    .then((response) => response)
    .catch((error) => error);
}

export async function getCarrierCreditLineService(carrierUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/credit-lines`)
    .then((response) => response)
    .catch((error) => error);
}

export async function getTransactionsService(
  carrierUuid: string,
  startDate: string,
  endDate: string,
) {
  return await axios
    .get(
      `${Config.REPORT_API_URL}/reports/carriers/${carrierUuid}/transactions?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
}

export async function listTransactionsByProgramService(
  programUuid: string,
  startDate: string,
  endDate: string,
) {
  return await axios
    .get(
      `${Config.REPORT_API_URL}/reports/programs/${programUuid}/transactions?startDateTime=${startDate}&endDateTime=${endDate}`,
    )
    .then((response) => response)
    .catch((error) => error);
}

export async function addDriverBulk(values: any, carrierUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/${carrierUuid}/drivers_bulk`, values)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function setCarrierPromptsService(
  values: any,
  carrierUuid: string,
) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/prompts`, values)
    .then((response) => response)
    .catch((error) => error);
}

export async function getCarrierPromptsService(carrierUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/prompts`)
    .then((response) => response)
    .catch((error) => error);
}

export async function getLimitsService(
  carrierUuid: string,
  requestDate: string,
) {
  return await axios
    .get(
      `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/limits?request_date=${requestDate}`,
    )
    .then((response) => response);
}

export async function setLimitsService(values: any, carrierUuid: string) {
  return await axios
    .post(
      `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/limits_bulk`,
      values,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function deactivateDriver(
  carrierUuid: string,
  driverUuid: string,
) {
  return await axios
    .post(
      `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/drivers/${driverUuid}/deactivate`,
    )
    .then((response) => {
      toast.success('Driver deactivated');
      return response;
    })
    .catch((error) => {
      toast.error(`deactivate driver failed, error details: ${error.message}`);
      throw error;
    });
}

export async function activateDriver(carrierUuid: string, driverUuid: string) {
  return await axios
    .post(
      `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/drivers/${driverUuid}/activate`,
    )
    .then((response) => {
      toast.success('Driver activated');
      return response;
    })
    .catch((error) => {
      toast.error(`activate driver failed, error details: ${error.message}`);
      throw error;
    });
}

export async function addDriver(values: any, carrierUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/drivers`, values)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getProgramCreditDetails(programUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/programs/${programUuid}/credit-details`)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export async function setProgramFeesService(
  values: any, // TODO: Update with defined object
) {
  const programUuid = values.program_uuid;
  return await axios
    .post(`${Config.CARRIER_API_URL}/${programUuid}/fees_bulk`, values)
    .then((response) => response)
    .catch((error) => error);
}

export async function getCreateProgramService() {
  return await axios
    .get(`${Config.CARRIER_API_URL}/programs`)
    .then((response) => response);
}

export const listProgramsService = async () => {
  return await axios
    .get(`${Config.CARRIER_API_URL}/programs`)
    .then((response) => response);
};

export const retrieveProgram = async (programUuid: string) => {
  return await axios
    .get(`${Config.CARRIER_API_URL}/programs/${programUuid}`)
    .then((response) => response);
};
