import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import * as helpers from './helpers';
import { Mixpanel } from '@/aggregator/config/mixpanel';
import { defaultEndDate, defaultStartDate } from '@/aggregator/helpers';
import { EXPORT_FILE_TYPES } from '@/aggregator/constants';

export function CarrierExportTransactions() {
  const [startDate, setStartDate] = React.useState<Date | null>(
    defaultStartDate,
  );
  const [endDate, setEndDate] = React.useState<Date | null>(defaultEndDate);

  const navigate = useNavigate();
  const client = useApolloClient();
  const { carrierId = '' } = useParams();

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  if (!carrier) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        exportFileType: EXPORT_FILE_TYPES.CSV,
      }}
      onSubmit={(values) => {
        helpers
          .exportTransactions(
            carrier.uuid,
            startDate,
            endDate,
            values.exportFileType,
          )
          .then(() => {
            Mixpanel.track('report_export_success', {
              location: location.pathname,
            });
          })
          .catch((e) =>
            Mixpanel.track('report_export_failed', {
              location: location.pathname,
              error: e,
            }),
          );
        handleOnClose();
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <Dialog open onClose={handleOnClose}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            Carrier: {carrier.name}
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Typography variant="h6" component="h2" mb={2}>
                Export Carrier Transactions
              </Typography>
              <FormControl
                sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    maxDate={endDate}
                    value={startDate}
                    onChange={(newValue) => {
                      isValid(newValue) && setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: '50%', mx: 1, mb: 1 }}
                      />
                    )}
                  />
                  <TimePicker
                    label="Start Time"
                    value={startDate}
                    onChange={(newValue) => {
                      isValid(newValue) && setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: '50%', mb: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    minDate={startDate}
                    value={endDate}
                    onChange={(newValue) => {
                      isValid(newValue) && setEndDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: '50%', mx: 1 }}
                      />
                    )}
                  />
                  <TimePicker
                    label="End Time"
                    value={endDate}
                    onChange={(newValue) => {
                      isValid(newValue) && setEndDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ width: '50%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth sx={{ ml: 1, pr: 1, mt: 1, mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  File Type{' '}
                </InputLabel>
                <Select
                  value={values.exportFileType}
                  label="File Type"
                  onChange={handleChange}
                  name="exportFileType"
                >
                  <MenuItem value={EXPORT_FILE_TYPES.CSV}>CSV</MenuItem>
                  <MenuItem value={EXPORT_FILE_TYPES.PDF}>PDF</MenuItem>
                  <MenuItem value={EXPORT_FILE_TYPES.XLS}>XLS</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleOnClose}>Cancel</SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={Boolean(!startDate || !endDate)}
              >
                Submit
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
