import axios, { AxiosResponse } from 'axios';
import { Config } from '@/aggregator/config/env';

const apiUrl = Config.MERCHANT_API_URL;

export const listMerchantLocationsServiceByCarrier = async (
  carrierUuid: string,
  programUuid?: string,
): Promise<AxiosResponse> => {
  let url: string;

  if (programUuid !== undefined) {
    url = `${apiUrl}/merchants/locations?carrierUuid=${carrierUuid}&programUuid=${programUuid}`;
  } else {
    url = `${apiUrl}/merchants/locations?carrierUuid=${carrierUuid}`;
  }
  return await axios
    .get(url)
    .then((response) => response)
    .catch((error) => error);
};
